import React from "react";
import ReactStars from "react-rating-stars-component";
import { useHistory, useParams } from "react-router-dom";
import Modal from "../../CommonFiles/UI Elements/Modal/Modal";

function QuizSurveyModal({ surveyFormSettings, onSubmit }) {
  const [formData, setFormData] = React.useState({});
  const [errorFields, setErrorFields] = React.useState([]);
  const [minLengthErrorFields, setMinLengthErrorFields] = React.useState([]);
  const history = useHistory();
  const { slug } = useParams();

  // This is to open the links (if any added by user) in the tnc field in a new tab
  React.useEffect(() => {
    // Find all .tnc-text containers
    const containers = document.querySelectorAll(".tnc-text");
    containers.forEach((container) => {
      const links = container.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    });
  }, [surveyFormSettings]);

  const handleChange = (label, value, rules) => {
    if (rules) {
      const maxLengthRule = rules.find((rule) => rule.maxLength !== undefined);
      const minLengthRule = rules.find((rule) => rule.minLength !== undefined);

      if (minLengthRule && value.length < Number(minLengthRule.minLength)) {
        setMinLengthErrorFields([
          ...errorFields,
          {
            label,
            value: "Minimum length should be " + minLengthRule.minLength,
          },
        ]);
      } else {
        setMinLengthErrorFields(
          minLengthErrorFields.filter((item) => item.label !== label)
        );
      }

      if (maxLengthRule && value.length > Number(maxLengthRule.maxLength)) {
        return;
      }
    }

    setFormData({
      ...formData,
      [label]: typeof value === "string" ? value.trim() : value,
    });

    if (errorFields.includes(label)) {
      setErrorFields(errorFields.filter((item) => item !== label));
    }
  };

  const renderField = (field) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) =>
              handleChange(field.label, e.target.value, field?.rules)
            }
            value={formData[field.label]}
          />
        );
      case "radio":
        return (
          <div className="radio-field">
            {field.options.map((option, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name={field.fieldName}
                  value={option}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [field.label]: option,
                    });
                    setErrorFields(
                      errorFields.filter((item) => item !== field.label)
                    );
                  }}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
            onChange={(e) =>
              handleChange(field.label, e.target.value, field?.rules)
            }
            value={formData[field.label]}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [field.label]: e.target.checked
                        ? formData[field.label]
                          ? [...formData[field.label], option]
                          : [option]
                        : formData[field.label].filter(
                          (item) => item !== option
                        ),
                    });
                    setErrorFields(
                      errorFields.filter((item) => item !== field.label)
                    );
                  }}
                />
                {field.isTnCField ? (
                  <div className="tnc-text">
                    <div dangerouslySetInnerHTML={{ __html: option }} />
                  </div>
                ) : (
                  option
                )}
              </label>
            ))}
          </div>
        );

      case "select":
        return (
          <select
            className="form-control"
            name={field.fieldName}
            onChange={(e) => handleChange(field.label, e.target.value, null)}
          >
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case "stars":
        return (
          <ReactStars
            count={5}
            size={30}
            value={formData[field.fieldName]}
            onChange={(newRating) => {
              setFormData({
                ...formData,
                [field.label]: newRating,
              });
              setErrorFields(
                errorFields.filter((item) => item !== field.label)
              );
            }}
            activeColor="#ffd700"
          />
        );
      default:
        return null;
    }
  };

  const onSurveySubmit = () => {
    const mandatoryFields = surveyFormSettings.filter(
      (item) => item.isMandatory
    );
    const errorFieldsArr = [];

    if (mandatoryFields.length > 0) {
      mandatoryFields.forEach((field) => {
        if (!formData[field.label]) {
          errorFieldsArr.push(field.label);
          setErrorFields(errorFieldsArr);
        }
      });
    }

    if (errorFieldsArr.length > 0 || minLengthErrorFields.length > 0) {
      return;
    }

    onSubmit(formData);
  };

  return (
    <Modal
      isOpen={true}
      title="Assessment Complete. Please share your experience and valuable feedback!"
      width="90%"
      titleAtCenter={true}
    >
      <div className="quiz-survey-modal-content">
        {surveyFormSettings.map((item) => {
          return (
            <div className={`input-field`} key={item.fieldName}>
              <div className="field-label-container">
                <label>
                  {item.label}
                  {item.isMandatory ? (
                    <span className="required-mark"> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
              {renderField(item)}

              {/* Errors */}
              {errorFields.includes(item.label) ? (
                <div style={{ color: "red" }}>This field is mandatory</div>
              ) : (
                minLengthErrorFields.find(
                  (err) => err.label === item.label
                ) && (
                  <div style={{ color: "red" }}>
                    {
                      minLengthErrorFields.find(
                        (err) => err.label === item.label
                      ).value
                    }
                  </div>
                )
              )}
            </div>
          );
        })}
      </div>
      <div className="quiz-survey-action-buttons">
        <button
          className="btn btn-outline-danger"
          onClick={() => {
            history.push(`/e/${slug}`);
          }}
        >
          Skip
        </button>

        <button className="btn btn-danger" onClick={onSurveySubmit}>
          Submit
        </button>
      </div>
    </Modal>
  );
}

export default QuizSurveyModal;
