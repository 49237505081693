import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import logo from "../../../images/main_logo.png";
import config from "../../CommonFiles/config.json";
import "./QuizHomePage.css";
import QuizPlayer from "./QuizPlayer";
import QuizSurveyModal from "./QuizSurveyModal";

const USER_TOKEN = localStorage.getItem("token");
const USER_INFO = localStorage.getItem("alldata");
const mainURL = Object.values(config["URL"]).join("");

const QuizHomePage = () => {
  const history = useHistory();
  const { slug, roundId } = useParams();
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [opportunityData, setOpportunityData] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [assessmentUser, setAssessmentUser] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [hasAssessmentStarted, setHasAssessmentStarted] = useState(false);

  useEffect(() => {
    if (slug && roundId && !isNaN(Number(roundId)) && USER_TOKEN && USER_INFO) {
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      setIsLoading(true);
      axios({
        method: "get",
        url: `${mainURL}/opportunity/${slug}?user_id=${JSON.parse(USER_INFO).id}`,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          setOpportunityData(response.data.data);

          const round = response.data.rounds.find(
            (r) => r.id === Number(roundId)
          );

          if (round) {
            if (round.assessmentStatus) {
              if (round.assessmentStatus === "COMPLETED") {
                history.push(`/e/${slug}`);
              } else if (round.assessmentStatus === "STARTED") {
                setHasAssessmentStarted(true);
              }
            }

            setRoundData(round);

            if (round.quiz_setting) {
              setQuizData(JSON.parse(round.quiz_setting));
            } else {
              onDataError();
            }
          } else {
            onDataError();
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          onDataError();
        });
    } else {
      history.push(`/e/${slug}`);
    }
    // eslint-disable-next-line
  }, []);

  const onDataError = (redirect = true, message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `${message || "Something went wrong. Please try again."}`,
      confirmButtonText: "OK",
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        redirect && history.push(`/e/${slug}`);
      }
    });
  };

  const formatDateTime = (date, time) => {
    // Combine date and time
    const dateTime = `${date} ${time}`;

    // Format the date
    return moment(dateTime, "YYYY-MM-DD HH:mm").format("Do MMM'YY h:mm A");
  };

  const formatDuration = (time) => {
    const duration = moment.duration(time);

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : ""} ${minutes > 0 ? `${minutes} min${minutes > 1 ? "s" : ""} ` : ""} ${seconds > 0 ? `${seconds} sec${seconds > 1 ? "s" : ""}` : ""}`;
  };

  const onStartQuiz = () => {
    if (confirmChecked) {
      if (USER_TOKEN) {
        const AuthStr = "Bearer ".concat(USER_TOKEN);
        axios({
          method: "post",
          url: `${mainURL}/assessment/start`,
          data: { opportunityId: opportunityData.id, roundId: roundData.id },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            if (response.data.status === 0) {
              onDataError(false, response.data.message);
            } else {
              const assessmentUserData = response.data.data;
              const proctor_info = assessmentUserData.proctor_info
                ? JSON.parse(assessmentUserData.proctor_info)
                : [];
              const totalNoOfViolations = proctor_info.reduce(
                (acc, p) => acc + p.violation,
                0
              );

              if (
                assessmentUserData.assessment_status !== "COMPLETED" &&
                assessmentUserData.assessment_status !== "GRADED" &&
                (!quizData.violationLimit ||
                  (quizData.violationLimit &&
                    totalNoOfViolations < quizData.violationLimit + 1))
              ) {
                setHasAssessmentStarted(true);
                setAssessmentUser(response.data.data);
                setQuizStarted(true);
              } else if (
                quizData.violationLimit &&
                totalNoOfViolations >= quizData.violationLimit + 1 &&
                assessmentUserData.assessment_status !== "COMPLETED" &&
                assessmentUserData.assessment_status !== "GRADED"
              ) {
                submitAssessmentOnViolationReach();
              } else {
                Swal.fire({
                  icon: "info",
                  title: "You have already completed the quiz.",
                  confirmButtonText: "OK",
                  showCancelButton: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push(`/e/${slug}`);
                  }
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            if (!err.response?.data?.auth) {
              onDataError(
                true,
                "You are not authorized to start the quiz. Please login to continue."
              );
            } else {
              onDataError(false);
            }
          });
      }
    }
  };

  const onQuizClose = () => {
    setQuizStarted(false);
  };

  const onFullScreenExit = () => {
    setQuizStarted(false);
    setConfirmChecked(false);

    if (!quizData.violationChecks.includes("fullScreen")) return;

    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const proctor_info = assessmentUser.proctor_info
      ? JSON.parse(assessmentUser.proctor_info)
      : [];

    const updatedProctoredInfo = proctor_info.find(
      (p) => p.type === "fullScreen"
    )
      ? proctor_info.map((p) => {
        if (p.type === "fullScreen") {
          return {
            ...p,
            violation: p.violation + 1,
          };
        } else {
          return p;
        }
      })
      : [...proctor_info, { type: "fullScreen", violation: 1 }];

    const violationLimit = quizData.violationLimit || 0;
    const totalNoOfViolations = updatedProctoredInfo.reduce(
      (total, p) => total + p.violation,
      0
    );

    setIsLoading(true);
    axios({
      method: "put",
      url: `${mainURL}/proctoring/${assessmentUser.id}`,
      data: {
        opportunityId: opportunityData.id,
        roundId: roundData.id,
        proctoringInfo: updatedProctoredInfo,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        if (violationLimit + 1 <= totalNoOfViolations) {
          submitAssessmentOnViolationReach();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("Error occured while updating proctoring info", {
          position: "top-center",
        });
      });
  };

  const submitAssessmentOnViolationReach = () => {
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: `${mainURL}/assessment/submit`,
      data: {
        opportunityId: opportunityData.id,
        roundId: roundData.id,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 0) {
          onDataError(false, response.data.message);
        } else {
          Swal.fire({
            icon: "success",
            title: "Assessment Complete!",
            text: "Your quiz has been terminated due to repeated violations. Thanks for undertaking the assessment and best wishes for the results 🚀",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              if (
                quizData.takeSurvey &&
                quizData.surveyFormSettings &&
                quizData.surveyFormSettings.length > 0 &&
                !assessmentUser.survey_info
              ) {
                setShowSurveyModal(true);
              } else {
                history.push(`/e/${slug}`);
              }
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.data?.auth === false) {
          onDataError(true, "Authentication failed. Please login to continue.");
        } else {
          onDataError(
            false,
            "Error occured while finishing the quiz. Please try again."
          );
        }
      });
  };

  const onAssessmentFinish = (automaticFinish) => {
    setAssessmentUser(null);
    setQuizStarted(false);

    Swal.fire({
      icon: `${automaticFinish ? "info" : "success"}`,
      title: `Assessment Complete!`,
      text: `${automaticFinish ? "You have reached the Time limit. Thanks for undertaking the assessment and best wishes for the results 🚀" : "Thanks for completing the assessment and best wishes for the results 🚀"}`,
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          quizData.takeSurvey &&
          quizData.surveyFormSettings &&
          quizData.surveyFormSettings.length > 0 &&
          !assessmentUser.survey_info
        ) {
          setShowSurveyModal(true);
        } else {
          history.push(`/e/${slug}`);
        }
      }
    });
  };

  const onSubmitSurvey = (data) => {
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: `${mainURL}/assessment/survey`,
      data: {
        opportunityId: opportunityData.id,
        roundId: roundData.id,
        surveyInfo: data,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        if (response.data.status === 0) {
          onDataError(false, response.data.message);
        } else {
          setShowSurveyModal(false);
          Swal.fire({
            icon: "success",
            title: "Feedback accepted!",
            text: "Thank you for taking the time to share your thoughts. Your feedback is invaluable and appreciate your participation.",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/e/${slug}`);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        onDataError();
      });
  };

  if (!quizStarted) {
    return (
      <div className="quiz-container">
        <ToastContainer />
        <div className="quiz-card">
          {isLoading ? (
            <div className="quiz-home-loader-container">
              <Loader type="Bars" color="#ee3835" height={100} width={100} />
            </div>
          ) : (
            opportunityData &&
            roundData &&
            quizData && (
              <>
                <div className="quiz-sections">
                  {/* Left Section */}
                  <div className="left-section">
                    <div className="company-header">
                      <div className="logo-container">
                        <img
                          src={opportunityData.logo_image}
                          alt={opportunityData.organization}
                          className="company-logo"
                        />
                      </div>
                      <div className="company-info">
                        <h1>{opportunityData.title}</h1>
                        <p>{opportunityData.organization}</p>
                      </div>
                    </div>

                    <div className="quiz-info">
                      <h2>{roundData.title}</h2>

                      <div className="quiz-details">
                        <div className="detail-row">
                          <span className="label">Start Date & Time:</span>
                          <span className="value">
                            {formatDateTime(
                              roundData.start_date,
                              roundData.start_time
                            )}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">End Date & Time:</span>
                          <span className="value">
                            {formatDateTime(
                              roundData.end_date,
                              roundData.end_time
                            )}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">Quiz Duration:</span>
                          <span className="value">
                            {formatDuration(quizData.quizDuration)}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="label">Total Questions:</span>
                          <span className="value">
                            {quizData.questionsToPresent}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right Section */}
                  <div className="right-section">
                    <span className="right-section-header">
                      {/* This text is getting set by CSS */}
                      <h2>{quizData.assessmentTitle}</h2>
                      <img src={logo} alt="logo" className="gp-logo-home" />
                    </span>
                    <hr />

                    <ul className="instructions-list">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: quizData.assessmentInstructions,
                        }}
                      ></div>
                    </ul>
                    <span className="quiz-instructions-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => setConfirmChecked(e.target.checked)}
                        checked={confirmChecked}
                      />
                      <p
                        onClick={() => setConfirmChecked(!confirmChecked)}
                        style={{ marginBottom: "unset", cursor: "pointer" }}
                      >
                        I confirm that I have read all the instructions and
                        guidelines carefully and ready to start the assessment.
                      </p>
                    </span>
                  </div>
                </div>
                <button
                  className="start-quiz-btn"
                  onClick={onStartQuiz}
                  disabled={!confirmChecked}
                >
                  {hasAssessmentStarted ? "Continue" : "Start"} Assessment
                </button>

                {/* Survey Modal */}
                {showSurveyModal && (
                  <QuizSurveyModal
                    surveyFormSettings={quizData.surveyFormSettings}
                    onSubmit={onSubmitSurvey}
                  />
                )}
              </>
            )
          )}
        </div>
      </div>
    );
  } else {
    if (!assessmentUser) {
      return (
        <div>
          {onDataError(true, "Some error occurred while starting the quiz")}
        </div>
      );
    } else {
      return (
        <QuizPlayer
          assessmentUser={assessmentUser}
          quizData={quizData}
          roundData={roundData}
          opportunityData={opportunityData}
          onFullScreenExit={onFullScreenExit}
          onAssessmentFinish={onAssessmentFinish}
          onQuizClose={onQuizClose}
        />
      );
    }
  }
};

export default QuizHomePage;
