import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaImage, FaMusic, FaTrash, FaUpload, FaVideo } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  getSignedUrls,
  uploadFileWithProgress,
} from "../../../services/uploadService";
import config from "../../CommonFiles/config.json";
import EmptyState from "../../CommonFiles/UI Elements/EmptyState";
import FullPageSimpleLoading from "../../CommonFiles/UI Elements/FullPageSimpleLoading";
import { getTextFromHTML, isEqual } from "../../CommonFiles/Utils/ObjectUtils";
import "./QuestionAuthor.css";
import MCQOptions from "./QuestionTypes/MCQOptions";
import SubjectiveOptions from "./QuestionTypes/SubjectiveOptions";

const mainURL = Object.values(config["URL"]).join("");

const BlankMCQQuestion = {
  questionType: "MCQ",
  questionText: "",
  questionMediaUrls: [],
  mcqType: "Single Answer",
  choices: [
    { id: 1, choice: "", type: null },
    { id: 2, choice: "", type: null },
  ],
  answers: [],
  overallMarks: 0,
  negativeMarks: 0,
  isDraft: true,
};

const BlankSubjectiveQuestion = {
  questionType: "Subjective",
  questionText: "",
  questionMediaUrls: [],
  answer: "",
  keywords: [{ id: 1, keywordVariants: [""], marks: null }],
  markingScheme: 1,
  keywordsSettings: 1,
  overallMarks: 0,
  negativeMarks: 0,
  isDraft: true,
};

export const FileTypes = {
  image: ["image/jpeg", "image/jpg", "image/png"],
  audio: ["audio/mpeg", "audio/wav", "audio/ogg", "audio/aac"],
  video: [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/avi",
    "video/mov",
    "video/mkv",
  ],
};

const QuestionAuthor = (props) => {
  const [questionList, setQuestionList] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [questionText, setQuestionText] = useState(
    questionList[selectedQuestionIndex]?.questionText || ""
  );
  const [errorList, setErrorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      !props.questionList &&
      props.quizSettings.questionIds &&
      props.quizSettings.questionIds.length > 0
    ) {
      const USER_TOKEN = localStorage.getItem("token");
      if (USER_TOKEN) {
        const AuthStr = "Bearer ".concat(USER_TOKEN);

        setIsLoading(true);
        axios({
          method: "post",
          url: mainURL + "/fetch-questions/",
          data: {
            ids: props.quizSettings.questionIds,
          },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            if (response.data.questions.length === 0) {
              setIsLoading(false);
              props.onQuestionListUpdate([]);
              props.onQuizSettingChange({
                ...props.quizSettings,
                questionIds: [],
              });
              props.onGoBack();
              return;
            }

            const updatedQuestionList = response.data.questions.map((q) => {
              return {
                ...q.questionJson,
                id: q.id,
              };
            });

            props.onQuestionListUpdate(updatedQuestionList);
            setQuestionList(updatedQuestionList);
            setErrorList([]);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            toast.error(
              "Unable to load the questions. Please try again later.",
              {
                position: "top-center",
                autoClose: 5000,
              }
            );
          });
      }
    } else if (props.questionList) {
      !isEqual(props.questionList, questionList) &&
        setQuestionList(props.questionList);
    }
    // eslint-disable-next-line
  }, [props.quizSettings, props.questionList]);

  useEffect(() => {
    if (questionList.length > 0) {
      const hasUnsavedChanges = questionList.some((q) => q.isDraft);
      props.hasUnsavedChanges(hasUnsavedChanges);
    }
  }, [questionList, props]);

  useEffect(() => {
    if (
      questionList.length > 0 &&
      selectedQuestionIndex !== null &&
      selectedQuestionIndex < questionList.length &&
      questionText !== questionList[selectedQuestionIndex].questionText
    ) {
      setQuestionText(questionList[selectedQuestionIndex].questionText);
    }
    // eslint-disable-next-line
  }, [questionList, selectedQuestionIndex]);

  const handleAddChoice = () => {
    handleQuestionListItemChange("choices", [
      ...questionList[selectedQuestionIndex].choices,
      {
        id: questionList[selectedQuestionIndex].choices.length + 1,
        choice: "",
        type: null,
      },
    ]);
  };

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...questionList[selectedQuestionIndex].choices];
    updatedChoices[index] = {
      ...updatedChoices[index],
      choice: value,
      type: value === "" ? null : "text",
    };
    handleQuestionListItemChange("choices", updatedChoices);
  };

  const handleDeleteChoice = (index) => {
    const updatedChoices = questionList[selectedQuestionIndex].choices.filter(
      (_, i) => i !== index
    );
    handleQuestionListItemChange("choices", updatedChoices);
  };

  const handleQuestionListItemChange = (property, newValue) => {
    if (questionList[selectedQuestionIndex][property] === newValue) return;

    setQuestionList((prevQuestionList) => {
      const updatedQuestionList = [...prevQuestionList];
      updatedQuestionList[selectedQuestionIndex] = {
        ...updatedQuestionList[selectedQuestionIndex],
        [property]: newValue,
        isDraft: true,
      };
      props.onQuestionListUpdate(updatedQuestionList);
      return updatedQuestionList;
    });
  };

  const handleQuestionTypeChange = (questionType) => {
    const newQuestion =
      questionType === "MCQ" ? BlankMCQQuestion : BlankSubjectiveQuestion;
    setQuestionList((prevQuestionList) => {
      const updatedQuestionList = [...prevQuestionList];
      updatedQuestionList[selectedQuestionIndex] = newQuestion;
      props.onQuestionListUpdate(updatedQuestionList);
      return updatedQuestionList;
    });
  };

  const handleMarksChange = (property, newValue) => {
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");
    handleQuestionListItemChange(property, Number(sanitizedValue));
  };

  const handleAddKeywordVariant = (keywordId) => {
    const updatedKeywords = questionList[selectedQuestionIndex].keywords.map(
      (keyword) => {
        if (keyword.id === keywordId) {
          keyword.keywordVariants.push("");
        }
        return keyword;
      }
    );
    handleQuestionListItemChange("keywords", updatedKeywords);
  };

  const handleRemoveKeywordVariant = (keywordId, index) => {
    const updatedKeywords = questionList[selectedQuestionIndex].keywords.map(
      (keyword) => {
        if (keyword.id === keywordId) {
          keyword.keywordVariants.splice(index, 1);
        }
        return keyword;
      }
    );
    handleQuestionListItemChange("keywords", updatedKeywords);
  };

  const handleKeywordMarksChange = (keywordId, value) => {
    const sanitizedValue = value ? value.replace(/[^0-9]/g, "") : null;
    const updatedKeywords = questionList[selectedQuestionIndex].keywords.map(
      (keyword) => {
        if (keyword.id === keywordId) {
          keyword.marks = sanitizedValue ? Number(sanitizedValue) : null;
        }
        return keyword;
      }
    );
    handleQuestionListItemChange("keywords", updatedKeywords);
  };

  const handleRemoveKeyword = (keywordId) => {
    const updatedKeywords = questionList[selectedQuestionIndex].keywords.filter(
      (keyword) => keyword.id !== keywordId
    );
    handleQuestionListItemChange("keywords", updatedKeywords);
  };

  const handleKeywordVariantChange = (keywordId, index, value) => {
    const updatedKeywords = questionList[selectedQuestionIndex].keywords.map(
      (keyword) => {
        if (keyword.id === keywordId) {
          keyword.keywordVariants[index] = value;
        }
        return keyword;
      }
    );
    handleQuestionListItemChange("keywords", updatedKeywords);
  };

  const handleAddKeyword = () => {
    const newKeyword = {
      id: questionList[selectedQuestionIndex].keywords.length + 1,
      keywordVariants: [""],
      marks: null,
    };
    handleQuestionListItemChange("keywords", [
      ...questionList[selectedQuestionIndex].keywords,
      newKeyword,
    ]);
  };

  const handleSaveQuestion = () => {
    const validateQuestionResult = validateQuestion(
      questionList[selectedQuestionIndex]
    );

    if (!validateQuestionResult.isValid) {
      setErrorList(validateQuestionResult.errors);
      return;
    }

    const USER_TOKEN = localStorage.getItem("token");

    const updatedQuestionList = [...questionList];
    updatedQuestionList[selectedQuestionIndex].isDraft = false;

    if (USER_TOKEN) {
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      const payload = updatedQuestionList[selectedQuestionIndex];

      const method = payload.id ? "put" : "post";
      const url = payload.id
        ? mainURL + "/questions/" + payload.id
        : mainURL + "/questions";
      setIsLoading(true);
      axios({
        method: method,
        url: url,
        data: payload,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          if (method === "post")
            updatedQuestionList[selectedQuestionIndex].id = response.data.id;
          setQuestionList(updatedQuestionList);
          setErrorList([]);
          props.onQuestionListUpdate(updatedQuestionList);
          props.onQuizSettingChange({
            ...props.quizSettings,
            questionIds: updatedQuestionList
              .map((q) => {
                if (q.id) {
                  return q.id;
                } else {
                  return false;
                }
              })
              .filter((item) => item),
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          const updatedQuestionList = [...questionList];
          updatedQuestionList[selectedQuestionIndex].isDraft = true;
          setQuestionList(updatedQuestionList);
          props.onQuestionListUpdate(updatedQuestionList);
          setIsLoading(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-center",
            autoClose: 5000,
          });
        });
    }
  };

  const handleDeleteQuestion = (index) => {
    Swal.fire({
      title: "Are you sure, you want to delete the question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (questionList[index].id) {
          const USER_TOKEN = localStorage.getItem("token");
          if (USER_TOKEN) {
            const AuthStr = "Bearer ".concat(USER_TOKEN);

            setIsLoading(true);
            axios({
              method: "delete",
              url: mainURL + "/questions/" + questionList[index].id,
              headers: { Authorization: AuthStr },
            })
              .then((response) => {
                const updatedQuestionList = [...questionList].filter(
                  (_, i) => i !== index
                );
                setSelectedQuestionIndex(null);
                setQuestionList(updatedQuestionList);
                setErrorList([]);
                props.onQuestionListUpdate(updatedQuestionList);
                props.onQuizSettingChange({
                  ...props.quizSettings,
                  questionIds: updatedQuestionList.map((q) => q.id),
                });
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
                toast.error("Something went wrong. Please try again later.", {
                  position: "top-center",
                  autoClose: 5000,
                });
              });
          }
        } else {
          setSelectedQuestionIndex(null);
          setQuestionList(questionList.filter((_, i) => i !== index));
          setErrorList([]);
          props.onQuestionListUpdate(
            questionList.filter((_, i) => i !== index)
          );
        }
      }
    });
  };

  const validateQuestion = (question) => {
    const errors = [];

    // Validate common fields
    if (!question.questionText.trim()) {
      errors.push({
        field: "questionText",
        message: "Question text is required",
      });
    }
    if (
      typeof question.overallMarks !== "number" ||
      question.overallMarks <= 0
    ) {
      question.markingScheme !== 2 &&
        errors.push({
          field: "overallMarks",
          message: "Overall marks must be more than 0",
        });
    }
    if (
      typeof question.negativeMarks !== "number" ||
      question.negativeMarks < 0
    ) {
      errors.push({
        field: "negativeMarks",
        message: "Negative marks cannot be negative",
      });
    }

    // Validate MCQ-specific fields
    if (question.questionType === "MCQ") {
      if (!question.mcqType) {
        errors.push({ field: "mcqType", message: "MCQ type is required" });
      }
      if (!Array.isArray(question.choices) || question.choices.length < 2) {
        errors.push({
          field: "choices",
          message: "At least two choices are required",
        });
      }
      question.choices.forEach((choice, index) => {
        if (!choice.choice.trim()) {
          errors.push({
            field: `choices[${index}]`,
            message: `Choice ${index + 1} must be filled`,
          });
        }
      });
      if (!Array.isArray(question.answers) || question.answers.length === 0) {
        errors.push({
          field: "answers",
          message: "At least one answer is required for MCQ",
        });
      }
    }

    // Validate Subjective-specific fields
    if (question.questionType === "Subjective") {
      if (!question.answer.trim()) {
        errors.push({ field: "answer", message: "Answer is required" });
      }

      if (
        !Array.isArray(question.keywords) ||
        question.keywords.length === 0 ||
        question.keywords.some(
          (keyword) =>
            !Array.isArray(keyword.keywordVariants) ||
            keyword.keywordVariants.length === 0 ||
            keyword.keywordVariants.some((variant) => !variant.trim())
        )
      ) {
        errors.push({
          field: "keywords",
          message: "All keywords and their variants must be properly filled",
        });
      }

      if (question.markingScheme === 2) {
        question.keywords.forEach((keyword, index) => {
          if (keyword.marks === null || typeof keyword.marks !== "number") {
            errors.push({
              field: `keywords[${index}].marks`,
              message: `Marks must be assigned for keyword ${index + 1}`,
            });
          }
        });
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  const handleFileUpload = (file, uploadType, choiceId = null) => {
    const validTypes =
      uploadType === "mcqOptionImage" || uploadType === "questionImage"
        ? FileTypes.image
        : uploadType === "questionAudio"
          ? FileTypes.audio
          : FileTypes.video;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

    if (validTypes.includes(file.type) && file.size <= maxSizeInBytes) {
      uploadFile(file, uploadType, choiceId);
    } else {
      toast.error("Invalid file type or size", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const uploadFile = async (file, uploadType, choiceId = null) => {
    setIsLoading(true);

    // Generate a new file name with a timestamp
    const newFileName = `${moment().valueOf()}-${file.name}`;
    const newFile = new File([file], newFileName, { type: file.type });

    try {
      // Get the signed URL for the new file name
      const signedUrl = await getSignedUrls([{ fileName: newFile.name }]);

      // Upload the file using the signed URL
      await uploadFileWithProgress(signedUrl[0].signedUploadURL, newFile);

      const fileUrl = signedUrl.find(
        (s) => s.fileName === newFile.name
      ).fileURL;

      if (
        uploadType === "questionImage" ||
        uploadType === "questionAudio" ||
        uploadType === "questionVideo"
      ) {
        const mediaUrls = [
          ...questionList[selectedQuestionIndex].questionMediaUrls,
        ];
        setQuestionList((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[selectedQuestionIndex].questionMediaUrls = [
            ...mediaUrls,
            {
              mediaUrl: fileUrl,
              mediaType: file.type,
            },
          ];
          updatedQuestions[selectedQuestionIndex].isDraft = true;
          props.onQuestionListUpdate(updatedQuestions);
          return updatedQuestions;
        });
      } else if (uploadType === "mcqOptionImage") {
        setQuestionList((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          const choice = updatedQuestions[selectedQuestionIndex].choices.find(
            (choice) => choice.id === choiceId
          );
          choice.type = "image";
          choice.choice = fileUrl;
          updatedQuestions[selectedQuestionIndex].isDraft = true;

          props.onQuestionListUpdate(updatedQuestions);
          return updatedQuestions;
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error("File upload failed:", error);
      setIsLoading(false);
      toast.error("File upload failed. Please try again later.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const hasError = (errors, fieldName) => {
    if (errors.some((error) => error.field === fieldName)) {
      return errors.find((error) => error.field === fieldName).message;
    }
    return null;
  };

  const handleRemoveError = (fieldName) => {
    setErrorList((prevErrors) =>
      prevErrors.filter((error) => error.field !== fieldName)
    );
  };

  const getOverallMarksValue = () => {
    if (
      questionList[selectedQuestionIndex].questionType === "Subjective" &&
      questionList[selectedQuestionIndex].markingScheme === 2
    ) {
      return questionList[selectedQuestionIndex].keywords.reduce(
        (total, keyword) => total + keyword.marks,
        0
      );
    }
    return questionList[selectedQuestionIndex].overallMarks;
  };

  return (
    <div className="question-author-container">
      {isLoading && (
        <FullPageSimpleLoading message="Working on it, please wait..." />
      )}
      <ToastContainer />
      <div className="sidebar">
        <span className="sidebar-header">
          <h3>Questions:</h3>
          <span className="add-question-buttons-container">
            <div className="add-dropdown">
              <button className="add-button">+ Add</button>
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setQuestionList([...questionList, BlankMCQQuestion]);
                    setSelectedQuestionIndex(questionList.length);
                    props.onQuestionListUpdate([
                      ...questionList,
                      BlankMCQQuestion,
                    ]);
                  }}
                >
                  Multiple Choice
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setQuestionList([...questionList, BlankSubjectiveQuestion]);
                    setSelectedQuestionIndex(questionList.length);
                    props.onQuestionListUpdate([
                      ...questionList,
                      BlankSubjectiveQuestion,
                    ]);
                  }}
                >
                  Subjective
                </div>
              </div>
            </div>
            <button
              className="question-upload-button"
              title="Bulk upload questions"
              onClick={props.onBulkUploadClick}
            >
              <FaUpload />
            </button>
          </span>
        </span>

        <div className="question-list">
          {questionList.map((question, index) => (
            <div
              className={`question-item ${selectedQuestionIndex === index && "selected"}`}
              key={index}
              onClick={() => {
                setSelectedQuestionIndex(index);
                setErrorList([]);
              }}
            >
              <span>
                <h4>
                  {index + 1}:{" "}
                  {question.questionText.trim() !== ""
                    ? `${getTextFromHTML(question.questionText).slice(0, 20)} 
                      ${getTextFromHTML(question.questionText).length > 20 ? "..." : ""}`
                    : "--"}
                </h4>
                <span className="question-type">{question.questionType}</span>
              </span>
              <span
                className="question-status"
                style={{
                  color: question.isDraft ? "red" : "green",
                  opacity: "60%",
                }}
              >
                {question.isDraft ? "Draft" : "Saved"}
              </span>
              <span className="question-delete-icon">
                <FaTrash
                  title="Delete Question"
                  onClick={() => handleDeleteQuestion(index)}
                />
              </span>
            </div>
          ))}

          {questionList.length === 0 && (
            <p className="no-questions">No questions added yet</p>
          )}
        </div>
      </div>

      <div className="main-content">
        {selectedQuestionIndex !== null &&
        questionList[selectedQuestionIndex] ? (
          <>
            <div className="question-header">
              <label>Question Type:</label>
              <select
                value={questionList[selectedQuestionIndex].questionType}
                onChange={(e) => {
                  handleQuestionTypeChange(e.target.value);
                  setErrorList([]);
                }}
              >
                <option value="MCQ">MCQ</option>
                <option value="Subjective">Subjective</option>
              </select>
            </div>

            <div className="question-body">
              <label>
                Question <span style={{ color: "red" }}>*</span>
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={questionText}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setQuestionText(data);
                  handleQuestionListItemChange("questionText", data);
                  handleRemoveError("questionText");
                }}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                  ],
                  placeholder: "Enter your question here...",
                  link: {
                    defaultProtocol: "https://",
                  },
                }}
              />
              {hasError(errorList, "questionText") && (
                <p className="error-text">
                  {hasError(errorList, "questionText")}
                </p>
              )}
              <br />

              <h4>Add Question Media</h4>
              {questionList[selectedQuestionIndex].questionMediaUrls.length ===
                0 && (
                <div className="media-buttons">
                  <span className="upload-image">
                    <input
                      id="questionImage"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleFileUpload(e.target.files[0], "questionImage");
                        e.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="questionImage">
                      <FaImage /> Photo
                    </label>
                  </span>
                  <span className="upload-image">
                    <input
                      id="questionAudio"
                      type="file"
                      accept="audio/*"
                      onChange={(e) => {
                        handleFileUpload(e.target.files[0], "questionAudio");
                        e.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="questionAudio">
                      <FaMusic /> Audio
                    </label>
                  </span>
                  <span className="upload-image">
                    <input
                      id="questionVideo"
                      type="file"
                      accept="video/*"
                      onChange={(e) => {
                        handleFileUpload(e.target.files[0], "questionVideo");
                        e.target.value = null;
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="questionVideo">
                      <FaVideo /> Video
                    </label>
                  </span>
                </div>
              )}

              {questionList[selectedQuestionIndex].questionMediaUrls.filter(
                (item) => FileTypes.image.includes(item.mediaType)
              ).length > 0 && (
                <div className="question-media">
                  {questionList[selectedQuestionIndex].questionMediaUrls
                    .filter((item) => FileTypes.image.includes(item.mediaType))
                    .map((item, index) => (
                      <div key={index} className="question-media-item-image">
                        <img
                          src={item.mediaUrl}
                          alt={`Question Media ${index + 1}`}
                          className="question-media-image"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(item.mediaUrl, "_blank");
                          }}
                          title="Click to view image in new tab"
                        />
                        <button
                          className="remove-media"
                          onClick={() => {
                            const fileIndex =
                              questionList[
                                selectedQuestionIndex
                              ].questionMediaUrls.indexOf(item);
                            setQuestionList((prevList) => {
                              const updatedList = [...prevList];
                              updatedList[
                                selectedQuestionIndex
                              ].questionMediaUrls.splice(fileIndex, 1);
                              props.onQuestionListUpdate(updatedList);
                              return updatedList;
                            });
                          }}
                          title="Remove image"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
              )}

              {questionList[selectedQuestionIndex].questionMediaUrls.filter(
                (item) => FileTypes.audio.includes(item.mediaType)
              ).length > 0 && (
                <div className="question-media">
                  {questionList[selectedQuestionIndex].questionMediaUrls
                    .filter((item) => FileTypes.audio.includes(item.mediaType))
                    .map((item, index) => (
                      <div key={index} className="question-media-item-image">
                        <audio
                          src={item.mediaUrl}
                          controls
                          className="question-media-audio"
                        />
                        <button
                          className="remove-media"
                          onClick={() => {
                            const fileIndex =
                              questionList[
                                selectedQuestionIndex
                              ].questionMediaUrls.indexOf(item);
                            setQuestionList((prevList) => {
                              const updatedList = [...prevList];
                              updatedList[
                                selectedQuestionIndex
                              ].questionMediaUrls.splice(fileIndex, 1);
                              props.onQuestionListUpdate(updatedList);
                              return updatedList;
                            });
                          }}
                          title="Remove audio"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
              )}

              {questionList[selectedQuestionIndex].questionMediaUrls.filter(
                (item) => FileTypes.video.includes(item.mediaType)
              ).length > 0 && (
                <div className="question-media">
                  {questionList[selectedQuestionIndex].questionMediaUrls
                    .filter((item) => FileTypes.video.includes(item.mediaType))
                    .map((item, index) => (
                      <div key={index} className="question-media-item-image">
                        <video
                          src={item.mediaUrl}
                          controls
                          className="question-media-video"
                        />
                        <button
                          className="remove-media"
                          onClick={() => {
                            const fileIndex =
                              questionList[
                                selectedQuestionIndex
                              ].questionMediaUrls.indexOf(item);
                            setQuestionList((prevList) => {
                              const updatedList = [...prevList];
                              updatedList[
                                selectedQuestionIndex
                              ].questionMediaUrls.splice(fileIndex, 1);
                              props.onQuestionListUpdate(updatedList);
                              return updatedList;
                            });
                          }}
                          title="Remove video"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
              )}

              {questionList[selectedQuestionIndex].questionType === "MCQ" ? (
                <MCQOptions
                  questionInfo={questionList[selectedQuestionIndex]}
                  handleQuestionListItemChange={handleQuestionListItemChange}
                  handleChoiceChange={handleChoiceChange}
                  handleAddChoice={handleAddChoice}
                  handleDeleteChoice={handleDeleteChoice}
                  errorList={errorList}
                  handleRemoveError={handleRemoveError}
                  handleFileUpload={handleFileUpload}
                />
              ) : (
                <SubjectiveOptions
                  questionInfo={questionList[selectedQuestionIndex]}
                  handleQuestionListItemChange={handleQuestionListItemChange}
                  handleAddKeyword={handleAddKeyword}
                  handleRemoveKeyword={handleRemoveKeyword}
                  handleAddKeywordVariant={handleAddKeywordVariant}
                  handleRemoveKeywordVariant={handleRemoveKeywordVariant}
                  handleKeywordVariantChange={handleKeywordVariantChange}
                  handleKeywordMarksChange={handleKeywordMarksChange}
                  errorList={errorList}
                  handleRemoveError={handleRemoveError}
                />
              )}

              <div className="mark-container">
                <h4>Mark</h4>
                <div className="mark-inputs">
                  <span style={{ display: "flex", gap: "30px" }}>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        Overall <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={getOverallMarksValue()}
                        disabled={
                          questionList[selectedQuestionIndex].questionType ===
                            "Subjective" &&
                          questionList[selectedQuestionIndex].markingScheme ===
                            2
                        }
                        onChange={(e) => {
                          handleMarksChange("overallMarks", e.target.value);
                          handleRemoveError("overallMarks");
                        }}
                      />
                      {hasError(errorList, "overallMarks") && (
                        <p className="error-text">
                          {hasError(errorList, "overallMarks")}
                        </p>
                      )}
                    </span>
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <label>Negative</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          questionList[selectedQuestionIndex].negativeMarks
                        }
                        onChange={(e) => {
                          handleMarksChange("negativeMarks", e.target.value);
                          handleRemoveError("negativeMarks");
                        }}
                      />
                      {hasError(errorList, "negativeMarks") && (
                        <p className="error-text">
                          {hasError(errorList, "negativeMarks")}
                        </p>
                      )}
                    </span>
                  </span>
                  <button
                    className="question-save-btn"
                    disabled={!questionList[selectedQuestionIndex].isDraft}
                    onClick={handleSaveQuestion}
                  >
                    Save Question
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <EmptyState message={"Add or Select a question to edit"} />
        )}
      </div>
    </div>
  );
};

export default QuestionAuthor;
