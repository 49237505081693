import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function useBlockNavigation(shouldBlock, onLeave) {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (shouldBlock && action === "POP") {
        const shouldLeave = window.confirm(
          "You are about to exit the assessment. The timer will continue and will not reset once you join back. Click Ok if you still want to exit."
        );

        if (shouldLeave) {
          if (onLeave) onLeave();
          return true;
        }
        return false;
      }
    });

    return () => unblock(); // Cleanup when component unmounts
  }, [history, shouldBlock, onLeave]);
}
