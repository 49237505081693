import moment from "moment-timezone";

function ConvertISTToLocal(istDate, istTime) {
  if (!istDate || !istTime) {
    return {
      localDate: null,
      localTime: null,
      localAbbreviation: null,
    };
  }

  const IST_TIMEZONE = "Asia/Kolkata";
  const localTimezone = moment.tz.guess();

  // Combine IST date and time
  const istDateTime = moment.tz(
    `${istDate} ${istTime}`,
    "YYYY-MM-DD HH:mm",
    IST_TIMEZONE
  );

  // Convert to local timezone
  const localDateTime = istDateTime.clone().tz(localTimezone);

  return {
    localDate: localDateTime.format("YYYY-MM-DD"), // Local date in YYYY-MM-DD format
    localTime: localDateTime.format("HH:mm"), // Local time in HH:mm format
    localAbbreviation: localDateTime.format("zz"), // Local time zone abbreviation
  };
}

export default ConvertISTToLocal;
