import React, { useEffect, useState } from "react";
import Footer from "../../CommonFiles/Footer";
import AddQuestions from "./AddQuestions";
import "./Managequiz.css";
import QuizSettingPage from "./QuizSettingPage";
import QuizTab from "./QuizTab";

function Managequiz(props) {
  const [activeStep, setActiveStep] = useState();
  const [quizSettings, setQuizSettings] = useState(
    props.quizSettings && props.quizSettings.assessmentTitle
      ? props.quizSettings
      : null
  );

  useEffect(() => {
    let activestatus = localStorage.getItem("quizStep");

    if (activestatus === null) {
      localStorage.setItem("quizStep", 1);
      setActiveStep("1");
    } else {
      localStorage.setItem("quizStep", activestatus);
      setActiveStep(activestatus);
    }
  }, [activeStep]);

  const activeSteps = (num) => {
    setActiveStep(num);
  };

  const handleOnNext = (newQuizSettings) => {
    setQuizSettings(newQuizSettings);
    localStorage.setItem("quizStep", 2);
    activeSteps(2);
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <QuizTab />

        {activeStep === "1" && (
          <QuizSettingPage
            // editStatus={props.location.state}
            quizIndexval={props.quizIndexval}
            newActiveStepValue={activeSteps}
            quizSettings={quizSettings}
            onNext={handleOnNext}
            onSave={(newSettings) => {
              props.onQuizSettingSave(newSettings);
            }}
            showSaveButton={
              props.quizSettings && props.quizSettings.assessmentTitle
            }
          />
        )}

        {activeStep === "2" && (
          <AddQuestions
            // editStatus={props.location.state}
            quizIndexval={props.quizIndexval}
            newActiveStepValue={activeSteps}
            quizSettings={quizSettings}
            onQuizSettingChange={(newQuizSettings) => {
              setQuizSettings(newQuizSettings);
            }}
            onQuizSettingSave={(newSettings) => {
              props.onQuizSettingSave(newSettings);
            }}
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Managequiz;
