import React from "react";
import { FiCheckCircle, FiClock, FiTarget, FiUsers } from "react-icons/fi";

const specialColors = {
  1: { bg: "#faf4d2", border: "#c2a91f" },
  2: { bg: "#fffefa", border: "#C0C0C0" },
  3: { bg: "#f7dcd5", border: "#CD7F32" },
};

function QuizLeaderboardUserCard({ team }) {
  const getScoreColor = (score) => {
    if (score >= 90) return "#64b82c"; // Green for high scores
    if (score >= 80) return "#3498db"; // Blue for medium-high scores
    if (score >= 65) return "#95a5a6"; // Grey for medium-low scores
    return "#e84848"; // Red for lower scores
  };

  // Calculate circle properties for progress indicator
  const calculateCircleProgress = (percentage) => {
    const radius = 24;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = circumference;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return {
      strokeDasharray,
      strokeDashoffset,
    };
  };

  const circleProgress = calculateCircleProgress(team.score);

  return (
    <div
      key={team.id}
      className="team-card"
      style={{
        backgroundColor: [1, 2, 3].includes(team.rank)
          ? specialColors[team.rank].bg
          : team.rank % 2 === 0
            ? "#f7f9fc"
            : "#fff",
        borderColor: [1, 2, 3].includes(team.rank)
          ? specialColors[team.rank].border
          : getScoreColor(team.score),
      }}
    >
      <div className="team-header">
        <div className="team-rank-name">
          <div className="team-rank-container">
            <h2
              className="team-rank"
              style={{
                color: [1, 2, 3].includes(team.rank)
                  ? specialColors[team.rank].border
                  : "#888888",
              }}
            >
              #{team.rank}
            </h2>
          </div>
          <div className="team-info">
            <h3 className="team-name">{team.name}</h3>
            <div className="team-members">
              <span className="member-icon">
                <FiUsers />
              </span>
              <span>{team.members.join(", ")}</span>
            </div>
          </div>
        </div>
        <div className="score-circle">
          <svg width="56" height="56" viewBox="0 0 56 56">
            <circle
              cx="28"
              cy="28"
              r="24"
              fill="none"
              stroke="#e6e6e6"
              strokeWidth="4"
            />
            <circle
              cx="28"
              cy="28"
              r="24"
              fill="none"
              stroke={getScoreColor(team.score)}
              strokeWidth="4"
              strokeDasharray={circleProgress.strokeDasharray}
              strokeDashoffset={circleProgress.strokeDashoffset}
              transform="rotate(-90 28 28)"
            />
            <text
              x="28"
              y="32"
              textAnchor="middle"
              fill="#333"
              fontSize="14"
              fontWeight="bold"
            >
              {team.score}%
            </text>
          </svg>
        </div>
      </div>

      <div className="team-metrics">
        <div className="metric">
          <div className="metric-icon time-icon">
            <FiClock />
          </div>
          <div className="metric-content">
            <div className="metric-label">Time</div>
            <div className="metric-value">{team.time}</div>
          </div>
        </div>

        <div className="metric">
          <div className="metric-icon questions-icon">
            <FiTarget />
          </div>
          <div className="metric-content">
            <div className="metric-label">Questions</div>
            <div className="metric-value">
              {team.questions}/{team.totalQuestions}
            </div>
          </div>
        </div>

        <div className="metric">
          <div className="metric-icon completion-icon">
            <FiCheckCircle />
          </div>
          <div className="metric-content">
            <div className="metric-label">Completion</div>
            <div className="completion-badge">{team.completion}%</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizLeaderboardUserCard;
