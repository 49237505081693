import React from "react";
import { QUESTION_STATUS } from "./QuestionStatusEnum";

function QuizPlayerQuestionsSidebar({
  currentQuestion,
  questions,
  onQuestionClick,
  savingResponse,
}) {
  return (
    <aside className="question-status">
      <div className="questions-grid">
        <div className="grid-header">
          <span className="grid-title">
            {questions.length} Question{questions.length > 1 ? "s" : ""}
          </span>
          <div className="status-indicators">
            <div className="indicator">
              <span className="dot answered"></span>
              <span className="indicator-text">Answered</span>
            </div>
            <div className="indicator">
              <span className="dot skipped"></span>
              <span className="indicator-text">Skipped</span>
            </div>
            <div className="indicator">
              <span className="dot"></span>
              <span className="indicator-text">To Be Answered</span>
            </div>
          </div>
        </div>

        <div className="grid">
          {Array.from({ length: questions.length }, (_, i) => (
            <button
              key={i + 1}
              className={`grid-button ${
                i === currentQuestion
                  ? "current"
                  : questions[i].status === QUESTION_STATUS.ANSWERED
                    ? "answered"
                    : questions[i].status === QUESTION_STATUS.SKIPPED
                      ? "skipped"
                      : ""
              }`}
              onClick={() => onQuestionClick(i)}
              disabled={savingResponse}
            >
              {String(i + 1).padStart(2, "0")}
            </button>
          ))}
        </div>
      </div>
    </aside>
  );
}

export default QuizPlayerQuestionsSidebar;
