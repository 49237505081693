import { Field, Form, Formik } from "formik";
import React from "react";
import CKEditor from "react-ckeditor-component";
import { FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { multiplyTime } from "../../CommonFiles/Utils/ObjectUtils";
import { CKEditorConfig } from "../../DynamicPages/FormSteps/FestivalDetailsEditor";
import "./QuizSettingPage.css";
import QuizSettingSurveySection from "./QuizSettingSurveySection";

const ViolationOptions = [
  { value: "fullScreen", label: "Conduct Assessment in Full Screen" },
  { value: "captureFaceReg", label: "Face capture during registration" },
  {
    value: "captureFaceAssessment",
    label: "Face detection throughout the assessment",
  },
  { value: "mobileAccess", label: "Mobile access registration" },
  { value: "trackLocation", label: "Track Geo Location" },
  { value: "trackIP", label: "Track IP Address" },
];

export const DefaultSurveyFormSettings = [
  {
    label: "How was the Assessment? (Rate out of 5)",
    fieldType: "stars",
    fieldName: "assessment_rating",
    isMandatory: true,
  },
  {
    label: "Do you have any suggestion?",
    fieldType: "textarea",
    fieldName: "assessment_user_suggestion",
    placeholder: "Enter Your Suggestion",
    isMandatory: false,
  },
];

function QuizSettingPage(props) {
  const formikRef = React.useRef(null);
  const [surveyFormSettings, setSurveyFormSettings] = React.useState(
    props.quizSettings?.surveyFormSettings
      ? props.quizSettings.surveyFormSettings
      : DefaultSurveyFormSettings
  );
  const [errors, setErrors] = React.useState({});

  const recalculateQuizDuration = (questionsToPresent, timePerQuestion) => {
    if (questionsToPresent === "" || timePerQuestion === "") {
      return;
    }

    formikRef.current.setFieldValue(
      "quizDuration",
      multiplyTime(timePerQuestion, questionsToPresent)
    );
  };

  const addOrRemoveFieldToViolationChecks = (check, field) => {
    if (check) {
      formikRef.current.setFieldValue("violationChecks", [
        ...formikRef.current.values.violationChecks,
        field,
      ]);
    } else {
      const filteredArray = formikRef.current.values.violationChecks.filter(
        (item) => item !== field
      );
      formikRef.current.setFieldValue("violationChecks", filteredArray);

      filteredArray.length === 0 &&
        formikRef.current.setFieldValue("violationLimit", "");
    }
  };

  const handleNextButtonClick = (action) => {
    const formData = formikRef.current.values;
    const fieldsToCheck = [
      "assessmentTitle",
      "assessmentInstructions",
      "questionsToPresent",
      "quizDuration",
    ];

    if (formData.timeBasedQuestions) fieldsToCheck.push("timePerQuestion");
    if (formData.violationChecks.length > 0)
      fieldsToCheck.push("violationLimit");

    fieldsToCheck.forEach((field) => {
      if (
        !formikRef.current.values[field] ||
        formikRef.current.values[field] === ""
      ) {
        formikRef.current.errors[field] = "This field is required";
      } else {
        formikRef.current.errors[field] = null;
      }
    });

    if (
      formikRef.current.errors.assessmentTitle ||
      formikRef.current.errors.assessmentInstructions ||
      formikRef.current.errors.questionsToPresent ||
      formikRef.current.errors.quizDuration ||
      formikRef.current.errors.timePerQuestion ||
      formikRef.current.errors.violationLimit
    ) {
      setErrors(formikRef.current.errors);
      toast.error("Please fill all the required fields", {
        position: "top-center",
      });
      return;
    }

    setErrors({});
    formikRef.current.errors = {};

    const formDataToSave = {
      ...formData,
      surveyFormSettings: formData.takeSurvey ? surveyFormSettings : null,
      questionIds: props.quizSettings ? props.quizSettings.questionIds : [],
    };

    if (action === "save") {
      props.onSave(formDataToSave);
    } else if (action === "next") {
      props.onNext(formDataToSave);
    }
  };

  return (
    <Formik
      initialValues={
        props.quizSettings
          ? props.quizSettings
          : {
              assessmentTitle: "",
              assessmentInstructions: "",
              questionsToPresent: "",
              quizDuration: "",
              whoCanPlay: "everyone",
              shuffleQuestions: false,
              shuffleOptions: false,
              timeBasedQuestions: false,
              timePerQuestion: "",
              browseQuestions: false,
              skipQuestions: false,
              revisitAnswers: false,
              fullScreen: false,
              violationLimit: "",
              captureFaceReg: false,
              captureFaceAssessment: false,
              mobileAccess: false,
              trackLocation: false,
              trackIP: false,
              violationChecks: [],
              takeSurvey: false,
            }
      }
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => (
        <Form className="form-container">
          <ToastContainer />
          <div className="quiz-settings-page">
            {/* Assessment Title */}
            <div className="form-group">
              <label className="input-title">Assessment Title</label>
              <Field
                name="assessmentTitle"
                type="text"
                placeholder="Enter title"
                className={`form-input ${errors.assessmentTitle ? "error-border" : ""} `}
              />
            </div>

            {/* Assessment Instructions */}
            <div
              className={`form-group ${errors.assessmentInstructions ? "ck-error-border" : ""}`}
            >
              <label className="input-title">Assessment Instructions</label>
              <CKEditor
                id="cke_editor1"
                content={values.assessmentInstructions}
                config={CKEditorConfig}
                events={{
                  change: (e) => {
                    const data = e.editor.getData();
                    setFieldValue("assessmentInstructions", data);
                  },
                }}
              />
            </div>

            <div className="form-row">
              {/* Number of Questions */}
              <div className="form-group" style={{ width: "48%" }}>
                <label className="input-title">
                  How many questions you want to present to candidate?
                </label>
                <Field
                  name="questionsToPresent"
                  type="text"
                  placeholder="Number of questions"
                  className={`form-input ${errors.questionsToPresent ? "error-border" : ""} `}
                  style={{ padding: "0.9rem" }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setFieldValue("questionsToPresent", value);
                    recalculateQuizDuration(value, values.timePerQuestion);
                  }}
                />
              </div>

              {/* Quiz Duration */}
              <div className="form-group" style={{ width: "48%" }}>
                <label className="input-title">Quiz Duration (HH:MM:SS)</label>
                <Field
                  name="quizDuration"
                  type="time"
                  step="1"
                  className={`form-input ${errors.quizDuration ? "error-border" : ""} `}
                  disabled={values.timeBasedQuestions}
                />
              </div>
            </div>

            {/* Who Can Play */}
            <div className="form-group">
              <label className="input-title">Who can play?</label>
              <div className="toggle-group">
                <label
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <Field
                    name="whoCanPlay"
                    type="radio"
                    value="everyone"
                    className="form-radio"
                  />{" "}
                  Everyone
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <Field
                    name="whoCanPlay"
                    type="radio"
                    value="leader"
                    className="form-radio"
                  />{" "}
                  Leader
                </label>
              </div>
            </div>

            <div
              className="flex"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <div
                className="left-form-group"
                style={{
                  flex: "1 1 30%",
                  marginRight: "1rem",
                  border: "1px solid",
                  padding: "1rem",
                  borderRadius: "15px",
                }}
              >
                <div className="form-row">
                  {/* Browse Questions */}
                  <div
                    className="form-group toggle-item"
                    style={{ width: "48%" }}
                  >
                    <label
                      className={`input-title ${values.timeBasedQuestions ? "disabled" : ""}`}
                    >
                      Question Browsing?
                    </label>
                    <label
                      className={`toggle ${values.timeBasedQuestions ? "disabled" : ""}`}
                    >
                      <Field
                        name="browseQuestions"
                        type="checkbox"
                        disabled={values.timeBasedQuestions}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                  {/* Skip Questions */}
                  <div
                    className="form-group toggle-item"
                    style={{ width: "48%" }}
                  >
                    <label className={`input-title`}>Skip Questions?</label>
                    <label className={`toggle`}>
                      <Field name="skipQuestions" type="checkbox" />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  {/* Revisit Answers */}
                  <div
                    className="form-group toggle-item"
                    style={{ width: "48%" }}
                  >
                    <label
                      className={`input-title ${values.timeBasedQuestions ? "disabled" : ""}`}
                    >
                      Revisit/Edit Answers?
                    </label>
                    <label
                      className={`toggle ${values.timeBasedQuestions ? "disabled" : ""}`}
                    >
                      <Field
                        name="revisitAnswers"
                        type="checkbox"
                        disabled={values.timeBasedQuestions}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                  {/* Shuffle Questions */}
                  <div
                    className="form-group toggle-item"
                    style={{ width: "48%" }}
                  >
                    <label className="input-title">
                      Shuffling of Questions?
                    </label>
                    <label className="toggle">
                      <Field name="shuffleQuestions" type="checkbox" />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  {/* Shuffle Options */}
                  <div
                    className="form-group toggle-item"
                    style={{ width: "48%" }}
                  >
                    <label className="input-title">Shuffling of Options?</label>
                    <label className="toggle">
                      <Field name="shuffleOptions" type="checkbox" />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="right-form-group"
                style={{
                  flex: "1",
                  border: "1px solid",
                  padding: "1rem",
                  borderRadius: "15px",
                }}
              >
                {/* Time-Based Questions */}
                <div
                  className="form-group toggle-item"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label className="input-title">Time-Based Questions</label>
                  <label className="toggle">
                    <Field
                      name="timeBasedQuestions"
                      type="checkbox"
                      onChange={(e) => {
                        setFieldValue("timeBasedQuestions", e.target.checked);
                        if (e.target.checked) {
                          setFieldValue("browseQuestions", false);
                          setFieldValue("revisitAnswers", false);
                          recalculateQuizDuration(
                            values.questionsToPresent,
                            values.timePerQuestion === ""
                              ? "00:00:00"
                              : values.timePerQuestion
                          );
                        } else {
                          setFieldValue("timePerQuestion", "");
                        }
                      }}
                    />
                    <span className="toggle-slider"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label className="input-title">
                    Time per question (HH:MM:SS)
                  </label>
                  <Field
                    name="timePerQuestion"
                    type="time"
                    step="1"
                    className={`form-input ${errors.timePerQuestion ? "error-border" : ""}`}
                    disabled={!values.timeBasedQuestions}
                    onChange={(e) => {
                      setFieldValue("timePerQuestion", e.target.value);
                      recalculateQuizDuration(
                        values.questionsToPresent,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Proctoring Configurations */}
            <div className="form-group proctoring-group">
              <label
                className="input-title"
                style={{
                  fontSize: "1.2rem",
                  marginBottom: "2rem",
                }}
              >
                Proctoring / Anti-Cheating Configurations
              </label>

              <div className="form-input-group">
                <div className="form-row">
                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title">
                      Conduct Assessment in
                      <br />
                      Full Screen
                    </label>
                    <label className="toggle">
                      <Field
                        name="fullScreen"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue("fullScreen", e.target.checked);
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "fullScreen"
                          );
                        }}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>

                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title">
                      Face capture during
                      <br />
                      registration
                    </label>
                    <label className="toggle">
                      <Field
                        name="captureFaceReg"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue("captureFaceReg", e.target.checked);
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "captureFaceReg"
                          );
                        }}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>

                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title">
                      Face detection throughout
                      <br />
                      the assessment
                    </label>
                    <label className="toggle">
                      <Field
                        name="captureFaceAssessment"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue(
                            "captureFaceAssessment",
                            e.target.checked
                          );
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "captureFaceAssessment"
                          );
                        }}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>

                <div className="form-row">
                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title disabled">
                      Mobile access registration
                    </label>
                    <label className="toggle disabled">
                      <Field
                        name="mobileAccess"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue("mobileAccess", e.target.checked);
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "mobileAccess"
                          );
                        }}
                        disabled
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>

                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title">Track Geo Location</label>
                    <label className="toggle">
                      <Field
                        name="trackLocation"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue("trackLocation", e.target.checked);
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "trackLocation"
                          );
                        }}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>

                  <div
                    className="form-group toggle-item"
                    style={{ width: "28%" }}
                  >
                    <label className="input-title">Track IP Address</label>
                    <label className="toggle">
                      <Field
                        name="trackIP"
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue("trackIP", e.target.checked);
                          addOrRemoveFieldToViolationChecks(
                            e.target.checked,
                            "trackIP"
                          );
                        }}
                      />
                      <span className="toggle-slider"></span>
                    </label>
                  </div>
                </div>

                <div
                  className={`form-group proctoring-group ${values.fullScreen || values.captureFaceReg || values.captureFaceAssessment || values.trackLocation || values.trackIP || values.mobileAccess ? "" : "hide"}`}
                >
                  <div
                    className={`form-group`}
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      justifyItems: "flex-start",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <label className="input-title" style={{ width: "25%" }}>
                      Violation Limit
                    </label>
                    <Field
                      name="violationLimit"
                      type="number"
                      placeholder="Enter limit"
                      className={`form-input ${errors.violationLimit ? "error-border" : ""}`}
                      min="1"
                      disabled={values.violationChecks?.length === 0}
                      style={{ width: "calc(75% - 1.5rem)" }}
                    />
                  </div>

                  <label className="input-title mb-3">Applies to</label>
                  <div
                    className={`form-group`}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1.5rem",
                    }}
                  >
                    {ViolationOptions.map((item, index) => {
                      if (values[item.value]) {
                        return (
                          <div
                            className="form-group violation-check-item"
                            key={index}
                          >
                            <Field
                              name={item.value}
                              type="checkbox"
                              className="violation-check"
                              checked={values.violationChecks.includes(
                                item.value
                              )}
                              onChange={(e) => {
                                addOrRemoveFieldToViolationChecks(
                                  e.target.checked,
                                  item.value
                                );
                              }}
                            />
                            <label>{item.label}</label>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* Survey */}
            <div className="form-group proctoring-group">
              <div className="form-row">
                <div
                  className="form-group toggle-item"
                  style={{ width: "30%", marginBottom: "unset" }}
                >
                  <label className="input-title">
                    Show Survey After Completion
                  </label>
                  <label className="toggle">
                    <Field name="takeSurvey" type="checkbox" />
                    <span className="toggle-slider"></span>
                  </label>
                </div>
              </div>

              {values.takeSurvey && (
                <QuizSettingSurveySection
                  currentSettings={surveyFormSettings}
                  setSettings={(newSettings) => {
                    setSurveyFormSettings(newSettings);
                  }}
                />
              )}
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              className="save-hosting-btn btn btn-primary"
              onClick={() => handleNextButtonClick("next")}
            >
              Next <FaArrowRight size={14} />
            </button>

            {props.showSaveButton && (
              <button
                type="button"
                className="save-hosting-btn btn btn-primary"
                onClick={() => handleNextButtonClick("save")}
              >
                Save & Close
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default QuizSettingPage;
