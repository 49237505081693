import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaClock, FaHourglass } from "react-icons/fa";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import config from "../../CommonFiles/config.json";
import useBlockNavigation from "../../CommonFiles/CustomHooks/useBlockNavigation";
import Timer from "../../CommonFiles/UI Elements/Timer/Timer";
import {
  calculateTimeSpent,
  extractMinutesSeconds,
  getIndividualTimeLeft,
  getTimeObject,
  getTimeObjectShort,
  isTotalTimeGreater,
  totalQuizTimeLeft,
} from "../../CommonFiles/Utils/ObjectUtils";
import { QUESTION_STATUS } from "./QuestionStatusEnum";
import "./QuizPlayer.css";
import QuizPlayerHeader from "./QuizPlayerHeader";
import QuizPlayerQuestionContent from "./QuizPlayerQuestionContent";
import QuizPlayerQuestionsSidebar from "./QuizPlayerQuestionsSidebar";

const USER_TOKEN = localStorage.getItem("token");
const mainURL = Object.values(config["URL"]).join("");

const QuizPlayer = ({
  assessmentUser,
  quizData,
  roundData,
  opportunityData,
  onFullScreenExit,
  onAssessmentFinish,
  onQuizClose,
}) => {
  const MountTime = new Date().toISOString();
  const [quizTimeLeft, setQuizTimeLeft] = useState(
    quizData
      ? assessmentUser.start_time
        ? getTimeObject(
            totalQuizTimeLeft(quizData.quizDuration, assessmentUser.start_time)
          )
        : getTimeObject(totalQuizTimeLeft(quizData.quizDuration, MountTime))
      : { hours: 0, minutes: 0, seconds: 0 }
  );

  const timePerQuestion =
    quizData && quizData.timeBasedQuestions
      ? getTimeObjectShort(extractMinutesSeconds(quizData.timePerQuestion))
      : null;

  const InitialQuestionData = JSON.parse(
    assessmentUser.individual_question_info
  ).map((question) => ({
    ...question,
    timeLeft: timePerQuestion
      ? getTimeObjectShort(
          getIndividualTimeLeft(
            `${timePerQuestion.minutes}:${timePerQuestion.seconds}`,
            question.timeSpent
          )
        )
      : null,
  }));

  const [currentQuestion, setCurrentQuestion] = useState(
    InitialQuestionData.findIndex(
      (question) => question.status === QUESTION_STATUS.NOT_PRESENTED
    ) === -1
      ? 0
      : InitialQuestionData.findIndex(
          (question) => question.status === QUESTION_STATUS.NOT_PRESENTED
        )
  );
  const [questions, setQuestions] = useState(InitialQuestionData);
  const [savingResponse, setSavingResponse] = useState(false);
  const [questionEntryTime, setQuestionEntryTime] = useState(null);
  const [individualQuestionTimeUp, setIndividualQuestionTimeUp] =
    useState(null);
  const elementRef = useRef(null);
  const qEntryTimeRef = useRef(null);
  const currentQuestionRef = useRef(
    InitialQuestionData.findIndex(
      (question) => question.status === QUESTION_STATUS.NOT_PRESENTED
    ) === -1
      ? 0
      : InitialQuestionData.findIndex(
          (question) => question.status === QUESTION_STATUS.NOT_PRESENTED
        )
  );
  const history = useHistory();
  const { slug } = useParams();

  useEffect(() => {
    qEntryTimeRef.current = questionEntryTime;
  }, [questionEntryTime]);

  useEffect(() => {
    currentQuestionRef.current = currentQuestion;
  }, [currentQuestion]);

  useEffect(() => {
    // Check login status
    if (!USER_TOKEN) {
      history.push("/");
      return;
    }

    // Log the start time of the assessment on start
    if (
      assessmentUser.assessment_status === "STARTED" &&
      !assessmentUser.start_time
    ) {
      axios({
        method: "post",
        url: `${mainURL}/assessment/startTime`,
        data: {
          opportunityId: opportunityData.id,
          roundId: roundData.id,
          startAssessmenTime: MountTime,
        },
        headers: { Authorization: "Bearer ".concat(USER_TOKEN) },
      })
        .then((response) => {
          toast.success("Quiz started, best of luck!", {
            position: "top-right",
            autoClose: 3000,
            style: {
              marginTop: "4rem",
            },
          });
        })
        .catch((err) => {
          console.error(err);
          if (err.response?.data?.auth === false) {
            onDataError(
              true,
              "Authentication failed. Please login to continue."
            );
          } else {
            onDataError(
              false,
              "Error occured while starting the quiz. Please try again."
            );
          }
        });
    }

    if (!quizData) return;

    const quizTimeLeftInSeconds =
      quizTimeLeft.hours * 3600 +
      quizTimeLeft.minutes * 60 +
      quizTimeLeft.seconds;

    if (!quizData.fullScreen) {
      const message =
        "You are about to exit the assessment. The timer will continue and will not reset once you join back. Click Ok if you still want to exit.";

      const handleBeforeUnload = (event) => {
        if (quizTimeLeftInSeconds > 0) {
          event.preventDefault();
          saveTimeForCurrentQuestion();
          event.returnValue = message;
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }

    if (quizData.fullScreen) {
      if (elementRef.current) {
        elementRef.current.requestFullscreen().catch((err) => {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error occurred while starting the quiz in full screen.",
            confirmButtonText: "OK",
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/e/${slug}`);
            }
          });
        });
      }

      // Function to detect fullscreen exit
      const handleFullscreenChange = () => {
        if (!document.fullscreenElement && quizTimeLeftInSeconds > 0) {
          saveTimeForCurrentQuestion(true);
        }
      };

      // Function to block right click
      const handleContextMenu = (e) => {
        e.preventDefault();
      };

      // Function to detect visibility change
      const handleVisibilityChange = () => {
        if (document.hidden && quizTimeLeftInSeconds > 0) {
          saveTimeForCurrentQuestion(true);
        }
      };

      // Function to handle tab switch
      const handleKeyDown = (event) => {
        if (event.altKey || event.metaKey) {
          event.preventDefault();
        }
      };

      // Function to handle navigating away from the page
      const handleBlur = () => {
        if (quizTimeLeftInSeconds > 0) {
          saveTimeForCurrentQuestion(true);
        }
      };

      // Function to handle back or refresh
      const handleBeforeUnload = (event) => {
        if (quizTimeLeftInSeconds > 0) {
          event.preventDefault();
          event.returnValue = "";
          saveTimeForCurrentQuestion(true);
        }
      };

      // Listen for back or refresh
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Listen for navigating away from the page
      window.addEventListener("blur", handleBlur);

      // Listen for tab switch
      window.addEventListener("keydown", handleKeyDown);

      // Listen for visibility change
      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Listen for fullscreen exit
      document.addEventListener("fullscreenchange", handleFullscreenChange);

      // Listen for right click
      window.addEventListener("contextmenu", handleContextMenu);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
        window.removeEventListener("contextmenu", handleContextMenu);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("blur", handleBlur);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check login status
    if (!USER_TOKEN) {
      history.push("/");
      return;
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentDateTime = new Date().toISOString();
    setQuestionEntryTime(currentDateTime);
  }, [currentQuestion]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestion < questions.length) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];

        if (
          updatedQuestions[currentQuestion].timeLeft &&
          !quizData.revisitAnswers &&
          (updatedQuestions[currentQuestion].status ===
            QUESTION_STATUS.SKIPPED ||
            updatedQuestions[currentQuestion].status ===
              QUESTION_STATUS.ANSWERED)
        ) {
          updatedQuestions[currentQuestion].timeLeft = {
            minutes: 0,
            seconds: 0,
          };
        }

        return updatedQuestions;
      });
    }

    // eslint-disable-next-line
  }, [currentQuestion]);

  // Timer functionality
  useEffect(() => {
    const mainTimer = setInterval(() => {
      // Only update the time if not loading
      if (!savingResponse) {
        setQuizTimeLeft((prev) => {
          const newSeconds = prev.seconds - 1;
          const newMinutes = newSeconds < 0 ? prev.minutes - 1 : prev.minutes;
          const newHours = newMinutes < 0 ? prev.hours - 1 : prev.hours;

          if (newHours < 0) {
            modifyCurrentQuestionAndMove(null);
            clearInterval(mainTimer);
            return { hours: 0, minutes: 0, seconds: 0 };
          }

          return {
            hours: newHours,
            minutes: newMinutes < 0 ? 59 : newMinutes,
            seconds: newSeconds < 0 ? 59 : newSeconds,
          };
        });
      }
    }, 1000);

    return () => clearInterval(mainTimer);

    // eslint-disable-next-line
  }, [savingResponse, quizTimeLeft]);

  useEffect(() => {
    if (!questions[currentQuestion].timeLeft) return;

    if (
      questions[currentQuestion].timeLeft.minutes <= 0 &&
      questions[currentQuestion].timeLeft.seconds <= 0 &&
      !savingResponse
    ) {
      if (
        currentQuestion < questions.length - 1 &&
        questions[currentQuestion + 1].status ===
          QUESTION_STATUS.NOT_PRESENTED &&
        individualQuestionTimeUp
      ) {
        setIndividualQuestionTimeUp(null);
        moveToNextQuestion();
      } else if (
        questions[currentQuestion].status !== QUESTION_STATUS.ANSWERED &&
        questions[currentQuestion].status !== QUESTION_STATUS.SKIPPED &&
        individualQuestionTimeUp
      ) {
        setIndividualQuestionTimeUp(null);
        modifyCurrentQuestionAndMove(null);
      }
      return;
    }

    const timer = setInterval(() => {
      // Only update the time if not loading
      if (!savingResponse) {
        setQuestions((prev) => {
          return prev.map((question, index) => {
            if (index === currentQuestion) {
              const newSeconds = question.timeLeft.seconds - 1;
              const newMinutes =
                newSeconds < 0
                  ? question.timeLeft.minutes - 1
                  : question.timeLeft.minutes;

              if (newMinutes === 0 && newSeconds === 0) {
                setIndividualQuestionTimeUp(true);
                clearInterval(timer);
                return {
                  ...question,
                  timeLeft: {
                    minutes: 0,
                    seconds: 0,
                  },
                };
              }

              return {
                ...question,
                timeLeft: {
                  minutes: newMinutes,
                  seconds: newSeconds < 0 ? 59 : newSeconds,
                },
              };
            } else {
              return question;
            }
          });
        });
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [currentQuestion, questions, savingResponse]);

  const formatNumber = (num) => String(num).padStart(2, "0");

  const onAnswer = (answer, type, checked) => {
    if (savingResponse) return;

    if (type === "Single Answer") {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[currentQuestion].response = {
          answer: [answer],
        };
        return updatedQuestions;
      });
    } else {
      if (checked) {
        setQuestions((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[currentQuestion].response = {
            answer: [
              ...updatedQuestions[currentQuestion].response.answer,
              answer,
            ],
          };
          return updatedQuestions;
        });
      } else {
        setQuestions((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[currentQuestion].response =
            updatedQuestions[currentQuestion].response.answer.length > 1
              ? {
                  answer: updatedQuestions[
                    currentQuestion
                  ].response.answer.filter((a) => a !== answer),
                }
              : null;
          return updatedQuestions;
        });
      }
    }
  };

  const onSubjectiveAnswer = (answer) => {
    if (savingResponse) return;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[currentQuestion].response =
        answer.trim().length > 0
          ? {
              answer,
            }
          : null;
      return updatedQuestions;
    });
  };

  const onDataError = (redirect = true, message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: `${message || "Something went wrong. Please try again."}`,
      confirmButtonText: "OK",
      showCancelButton: false,
      target: elementRef.current,
    }).then((result) => {
      if (result.isConfirmed) {
        redirect && history.push(`/e/${slug}`);
      }
    });
  };

  const moveToNextQuestion = () => {
    if (savingResponse) return;

    modifyCurrentQuestionAndMove(currentQuestion + 1);
  };

  const moveToPreviousQuestion = () => {
    if (savingResponse) return;

    modifyCurrentQuestionAndMove(currentQuestion - 1);
  };

  const onQuestionClick = (index) => {
    if (
      savingResponse ||
      index === currentQuestion ||
      !quizData.browseQuestions ||
      isNextQuestionButtonDisabled()
    )
      return;

    modifyCurrentQuestionAndMove(index);
  };

  const modifyCurrentQuestionAndMove = (moveTo) => {
    setSavingResponse(true);

    const updatedQuestions = JSON.parse(JSON.stringify(questions));

    updatedQuestions[currentQuestion].status = questions[currentQuestion]
      .response
      ? QUESTION_STATUS.ANSWERED
      : QUESTION_STATUS.SKIPPED;

    updatedQuestions[currentQuestion].timeSpent = timePerQuestion
      ? isTotalTimeGreater(
          timePerQuestion,
          getTimeObjectShort(updatedQuestions[currentQuestion].timeSpent)
        )
        ? calculateTimeSpent(
            updatedQuestions[currentQuestion].timeSpent,
            questionEntryTime
          )
        : `${formatNumber(timePerQuestion.minutes)}:${formatNumber(timePerQuestion.seconds)}`
      : calculateTimeSpent(
          updatedQuestions[currentQuestion].timeSpent,
          questionEntryTime
        );

    axios({
      method: "put",
      url: mainURL + "/response/" + assessmentUser.id,
      data: updatedQuestions[currentQuestion],
      headers: { Authorization: "Bearer ".concat(USER_TOKEN) },
    })
      .then((response) => {
        setQuestions(updatedQuestions);
        setSavingResponse(false);
        if (moveTo !== null) {
          setCurrentQuestion(moveTo);
        } else {
          onFinishQuiz(false, true);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error occured while saving response", {
          position: "top-right",
          style: {
            marginTop: "4rem",
          },
        });
        setSavingResponse(false);
      });
  };

  const saveTimeForCurrentQuestion = (
    fullScreenViolation = false,
    quizClose = false
  ) => {
    setSavingResponse(true);
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    updatedQuestions[currentQuestionRef.current].timeSpent = timePerQuestion
      ? isTotalTimeGreater(
          timePerQuestion,
          getTimeObjectShort(
            updatedQuestions[currentQuestionRef.current].timeSpent
          )
        )
        ? calculateTimeSpent(
            updatedQuestions[currentQuestionRef.current].timeSpent,
            qEntryTimeRef.current
          )
        : `${formatNumber(timePerQuestion.minutes)}:${formatNumber(timePerQuestion.seconds)}`
      : calculateTimeSpent(
          updatedQuestions[currentQuestionRef.current].timeSpent,
          qEntryTimeRef.current
        );

    updatedQuestions[currentQuestionRef.current].timeLeft = timePerQuestion
      ? getTimeObjectShort(
          getIndividualTimeLeft(
            `${timePerQuestion.minutes}:${timePerQuestion.seconds}`,
            updatedQuestions[currentQuestionRef.current].timeSpent
          )
        )
      : null;

    axios({
      method: "put",
      url: mainURL + "/response/" + assessmentUser.id,
      data: updatedQuestions[currentQuestionRef.current],
      headers: { Authorization: "Bearer ".concat(USER_TOKEN) },
    })
      .then((response) => {
        setQuestions(updatedQuestions);
        setSavingResponse(false);
        if (fullScreenViolation) {
          fullScreenViolationAction();
        }

        if (quizClose) {
          onQuizClose();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error occured while saving response", {
          position: "top-right",
          style: {
            marginTop: "4rem",
          },
        });
        setSavingResponse(false);
        if (fullScreenViolation) {
          fullScreenViolationAction();
        }

        if (quizClose) {
          onQuizClose();
        }
      });

    const fullScreenViolationAction = () => {
      Swal.fire({
        icon: "warning",
        title: "Assessment Violation",
        text: "You are about to exit the full screen mode during assessment. Please continue assessment else it will be counted as a violation. Repeated violations will result in disqualification",
        confirmButtonText: "Exit",
        showCancelButton: true,
        cancelButtonText: "Continue Assessment",
        target: elementRef.current,
      }).then((result) => {
        if (result.isConfirmed) {
          onFullScreenExit();
        } else {
          if (elementRef.current) {
            elementRef.current.requestFullscreen().catch((err) => {
              console.error(err);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error occurred while starting the quiz in full screen.",
                confirmButtonText: "OK",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push(`/e/${slug}`);
                }
              });
            });
          }
        }
      });
    };
  };

  useBlockNavigation(true, saveTimeForCurrentQuestion);

  const isNextQuestionButtonDisabled = () => {
    if (savingResponse) return true;
    if (quizData.skipQuestions) return false;

    const currentQ = questions[currentQuestion];
    if (currentQ.response) return false;

    return timePerQuestion
      ? currentQ.timeLeft.minutes > 0 || currentQ.timeLeft.seconds > 0
      : true;
  };

  const onFinishQuiz = (showConfirmPopup, automaticFinish = false) => {
    // Change the status of the current question
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    updatedQuestions[currentQuestion].status = questions[currentQuestion]
      .response
      ? QUESTION_STATUS.ANSWERED
      : QUESTION_STATUS.SKIPPED;

    updatedQuestions[currentQuestion].timeSpent = timePerQuestion
      ? isTotalTimeGreater(
          timePerQuestion,
          getTimeObjectShort(updatedQuestions[currentQuestion].timeSpent)
        )
        ? calculateTimeSpent(
            updatedQuestions[currentQuestion].timeSpent,
            questionEntryTime
          )
        : `${formatNumber(timePerQuestion.minutes)}:${formatNumber(timePerQuestion.seconds)}`
      : calculateTimeSpent(
          updatedQuestions[currentQuestion].timeSpent,
          questionEntryTime
        );

    if (showConfirmPopup) {
      Swal.fire({
        icon: "question",
        title: "Are you sure?",
        text: "Are you sure to submit the assessment?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        target: elementRef.current,
      }).then((result) => {
        if (result.isConfirmed) {
          finishAssessment();
        }
      });
    } else {
      finishAssessment();
    }

    function finishAssessment() {
      axios({
        method: "put",
        url: mainURL + "/response/" + assessmentUser.id,
        data: updatedQuestions[currentQuestion],
        headers: { Authorization: "Bearer ".concat(USER_TOKEN) },
      })
        .then((response) => {
          if (response.data.status === 1) {
            setQuestions(updatedQuestions);

            // Set the quiz as finished
            callFinishApi();
          } else {
            toast.error("Error occured while saving response", {
              position: "top-right",
              style: {
                marginTop: "4rem",
              },
            });
            setSavingResponse(false);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error occured while saving response", {
            position: "top-right",
            style: {
              marginTop: "4rem",
            },
          });
          setSavingResponse(false);
        });
    }

    function callFinishApi() {
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: "post",
        url: `${mainURL}/assessment/submit`,
        data: {
          opportunityId: opportunityData.id,
          roundId: roundData.id,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          if (response.data.status === 0) {
            onDataError(false, response.data.message);
          } else {
            onAssessmentFinish(automaticFinish);
          }
        })
        .catch((err) => {
          if (err.response?.data?.auth === false) {
            onDataError(
              true,
              "Authentication failed. Please login to continue."
            );
          } else {
            onDataError(
              false,
              "Error occured while finishing the quiz. Please try again."
            );
          }
        });
    }
  };

  return (
    <div className="quiz-wrapper" ref={elementRef}>
      <ToastContainer />
      <div className="quiz-player-container">
        {/* Header */}
        <QuizPlayerHeader
          opportunityData={opportunityData}
          quizTimeLeft={quizTimeLeft}
          onFinishQuiz={onFinishQuiz}
        />
        {quizTimeLeft.hours <= 0 &&
        quizTimeLeft.minutes <= 0 &&
        quizTimeLeft.seconds <= 0 ? (
          // <QuizTimesUpModal onFinishQuiz={onFinishQuiz} />
          <div className="quiz-times-up-response-submit-text">
            <Loader type="ThreeDots" color="#ff4757" height={100} width={100} />
            <p>Assessment time's up! Submitting your answers...</p>
          </div>
        ) : (
          <div className="content-wrapper">
            {/* Main Question Area */}
            <main className="question-area">
              <div className="question-header">
                <div className="question-meta">
                  <span className="question-number">
                    QUESTION {currentQuestion + 1} / {questions.length}
                  </span>
                  <span className="marks">
                    {
                      questions[currentQuestion].question.questionJson
                        .overallMarks
                    }{" "}
                    {questions[currentQuestion].question.questionJson
                      .overallMarks > 1
                      ? "Marks"
                      : "Mark"}
                  </span>
                </div>

                {questions[currentQuestion].timeLeft && (
                  <Timer
                    timeLeft={
                      questions[currentQuestion].timeLeft.minutes * 60 +
                      questions[currentQuestion].timeLeft.seconds
                    }
                    timePerQuestions={
                      timePerQuestion.minutes * 60 + timePerQuestion.seconds
                    }
                  >
                    <div className="time-display">
                      <span>
                        <FaClock />{" "}
                      </span>
                      <div className="timer-blocks">
                        {formatNumber(
                          questions[currentQuestion].timeLeft.minutes
                        )}

                        <span className="separator">:</span>

                        {formatNumber(
                          questions[currentQuestion].timeLeft.seconds
                        )}
                      </div>
                    </div>
                  </Timer>
                )}
              </div>

              <QuizPlayerQuestionContent
                currentQuestion={questions[currentQuestion]}
                savingResponse={savingResponse}
                onAnswer={onAnswer}
                onSubjectiveAnswer={onSubjectiveAnswer}
                quizData={quizData}
              />

              <div className="navigation-buttons">
                <button
                  className="prev-btn"
                  disabled={
                    currentQuestion === 0 ||
                    savingResponse ||
                    !quizData.browseQuestions
                  }
                  onClick={moveToPreviousQuestion}
                  title="Previous Question"
                >
                  {savingResponse ? <FaHourglass /> : "←"}
                </button>
                <span style={{ display: "flex", gap: "10px" }}>
                  <button
                    className="clear-btn"
                    disabled={
                      savingResponse ||
                      !questions[currentQuestion].response ||
                      (timePerQuestion &&
                        questions[currentQuestion].timeLeft.minutes <= 0 &&
                        questions[currentQuestion].timeLeft.seconds <= 0) ||
                      (!quizData.revisitAnswers &&
                        questions[currentQuestion].status ===
                          QUESTION_STATUS.ANSWERED)
                    }
                    onClick={() => {
                      setQuestions((prevQuestions) => {
                        const updatedQuestions = [...prevQuestions];
                        updatedQuestions[currentQuestion].response = null;
                        return updatedQuestions;
                      });
                    }}
                  >
                    Clear Response
                  </button>
                  {currentQuestion === questions.length - 1 ? (
                    <button className="finish-button" onClick={onFinishQuiz}>
                      Finish Quiz
                    </button>
                  ) : (
                    <button
                      className="next-btn"
                      disabled={isNextQuestionButtonDisabled()}
                      onClick={moveToNextQuestion}
                    >
                      {savingResponse ? "Saving..." : "Next Question →"}
                    </button>
                  )}
                </span>
              </div>
            </main>

            {/* Right Side Question Status */}
            <QuizPlayerQuestionsSidebar
              currentQuestion={currentQuestion}
              questions={questions}
              onQuestionClick={onQuestionClick}
              savingResponse={savingResponse}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizPlayer;
