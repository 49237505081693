import React, { useEffect, useState } from "react";
import { FaUserCheck, FaUserFriends } from "react-icons/fa";

import Footer from "../../CommonFiles/Footer";
import Header from "../../CommonFiles/Header";
import "./QuizLeaderboard.css";
import QuizLeaderboardUserCard from "./QuizLeaderboardUserCard";

const USER_TOKEN = localStorage.getItem("token");

// Sample data based on the image
const teamsData = [
  {
    id: 1,
    rank: 1,
    name: "Code Warriors",
    members: ["John Doe", "Jane Smith"],
    time: "20m 0s",
    questions: 48,
    totalQuestions: 50,
    completion: 96,
    score: 95,
  },
  {
    id: 2,
    rank: 2,
    name: "Tech Titans",
    members: ["Alice Johnson", "Bob Wilson"],
    time: "22m 30s",
    questions: 45,
    totalQuestions: 50,
    completion: 90,
    score: 88,
  },
  {
    id: 3,
    rank: 3,
    name: "Debug Dragons",
    members: ["Charlie Brown", "Diana Prince"],
    time: "23m 20s",
    questions: 42,
    totalQuestions: 50,
    completion: 84,
    score: 85,
  },
  {
    id: 4,
    rank: 4,
    name: "Binary Bosses",
    members: ["Eva Green", "Frank Miller"],
    time: "25m 0s",
    questions: 41,
    totalQuestions: 50,
    completion: 82,
    score: 82,
  },
  {
    id: 5,
    rank: 5,
    name: "Code Ninjas",
    members: ["Grace Lee", "Henry Kim"],
    time: "26m 30s",
    questions: 40,
    totalQuestions: 50,
    completion: 80,
    score: 80,
  },
  {
    id: 6,
    rank: 6,
    name: "Debuggers",
    members: ["Isabella Lee", "Jack Lee"],
    time: "27m 20s",
    questions: 39,
    totalQuestions: 50,
    completion: 78,
    score: 78,
  },
  {
    id: 7,
    rank: 7,
    name: "Tech Wizards",
    members: ["Katherine Lee", "Liam Lee"],
    time: "28m 0s",
    questions: 38,
    totalQuestions: 50,
    completion: 76,
    score: 76,
  },
  {
    id: 8,
    rank: 8,
    name: "Code Masters",
    members: ["Mia Lee", "Noah Lee"],
    time: "29m 30s",
    questions: 37,
    totalQuestions: 50,
    completion: 74,
    score: 74,
  },
];

function QuizLeaderboard() {
  const [loginstatus, setLoginstatus] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const teamsPerPage = 5;

  // Calculate teams to display
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = teamsData.slice(indexOfFirstTeam, indexOfLastTeam);

  useEffect(() => {
    if (!USER_TOKEN) {
      if (localStorage.getItem("loginpopup") === "false") {
        setLoginstatus(true);
        localStorage.setItem("loginpopup", "true");
      }
    }

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const element = document.getElementById("leaderboard-start-heading");
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 80;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div className="quiz-leaderboard-page">
      <Header openlogin={loginstatus} />

      <div className="quiz-leaderboard-main-content">
        <div className="leaderboard-container">
          <div className="leaderboard-header-card">
            <div className="leaderboard-header-left-content">
              <h3>Consult-Blitz</h3>
              <h6>Round 1 : Pre-Submission Knowledge Check</h6>

              <div className="leaderboard-info">
                <FaUserFriends aria-hidden="true" />
                <span>
                  <b>Registered Teams:</b> 107
                </span>
              </div>

              <div className="leaderboard-info">
                <FaUserCheck aria-hidden="true" />
                <span>
                  <b>Participated till now:</b> 56
                </span>
              </div>
            </div>

            <div className="leaderboard-header-right-content">
              <h4>Round End Date</h4>
              <div className="round-end-date">28th May 2023</div>
            </div>
          </div>

          <div className="leaderboard-logged-user-card">
            <h5>Your Ranking</h5>
            <QuizLeaderboardUserCard team={teamsData[5]} />
          </div>
          <br />

          <h5 id="leaderboard-start-heading">Leaderboard</h5>
          {currentTeams.map((team) => (
            <QuizLeaderboardUserCard key={team.id} team={team} />
          ))}

          <div className="pagination">
            {Array.from(
              { length: Math.ceil(teamsData.length / teamsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default QuizLeaderboard;
